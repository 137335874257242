::selection {
  background: $primary;
  color: $white;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 1.15rem;
  margin-left: 0;
}

@keyframes closing-in {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(0.1, 0.1);

  }
  100% {
    transform: rotate(-45deg) translate(-7.5px, -7.5px) scale(1, 1);
    opacity: 1;
  }
}

@keyframes closing-out {
  100% {
    opacity: 0;
    transform: translate(0, 0) scale(0.1, 0.1);

  }
  99% {
    opacity: 1;
  }
  0% {
    transform: rotate(-45deg) translate(-7.5px, -7.5px) scale(1, 1);
    opacity: 1;
  }
}

svg.fourofour {
  width: 100%;
  height: auto;
}

html {
  font-display: fallback;
  a {
    :hover, :focus, :focus-within, :visited {
      color: inherit;
    }
  }
  button {
    &:focus, &:target {
      outline: none;
    }
  }
  polygon#close {
    opacity: 0;
    transform-origin: center;
    transform: rotate(0) scale(0.1);
    &.vis {
      animation: closing-in 0.4s cubic-bezier(.37, .01, .64, 0.4) forwards;
    }
    &.closing {
      animation: closing-out 0.4s cubic-bezier(.37, .01, .64, 0.4) forwards;
    }

  }
  .navbar-toggler {
    path {
      transition: all .3s ease-in-out;
    }
  }
  .url {
    padding-left: 2rem;
    position: relative;
    &:before {
      height: 1rem;
      width: 1rem;
      position: absolute;
      content: " ";
      background: url('../images/url-icon.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      left: 0.5rem;
      top: 0.75rem;
    }
  }
  .btn-primary {
    transition: box-shadow 0.5s ease;
    &:hover {
      -webkit-box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.16);
      background-color: $primary;
      border-color: $primary;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      background-color: $primary;
      border: none;
    }
  }
  .mb-33 {
    margin-bottom: 12rem;
    @include media-breakpoint-down(lg) {
      margin-bottom: 3rem;
    }
  }
  .mb-23 {
    margin-bottom: 6rem;
    @include media-breakpoint-down(lg) {
      margin-bottom: 1.5rem;
    }
  }
  body {
    padding-top: 99px;
    @include media-breakpoint-down(lg) {
      padding-top: 65px;
    }
    .wrap {
      max-width: 1920px;
      margin: 0 auto;
    }
  }
  .stick {
    @include media-breakpoint-up(xl) {
      width: 500px;
      position: fixed;
      top: 115px;
      left: 54.85vw;
    }
  }
  section {
    @include media-breakpoint-up(lg) {
      ul {
        li:before {
          margin-left: -1.1rem;
        }
      }
    }
    div.row {
      overflow: hidden;
      &.intro {
        margin-bottom: 3rem;
        height: 417px;
        background-image: url("../images/duo-kraft-pattern-web.svg");
        background-size: 250px 250px;
        background-position: center;
        .h1 {
          margin: 0.5rem 0 0 0;
          background-color: $primary;
        }
        @include media-breakpoint-down(sm) {
          height: 270px;
        }
      }
    }

    li {
      list-style-type: none;
      margin-bottom: 1rem;
      &:before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $primary; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1.1em; /* Also needed for space (tweak if needed) */
        margin-left: -1.05em; /* Also needed for space (tweak if needed) */
      }
    }
  }

}