//rotation Closing animation
@keyframes closing-in {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(0.1, 0.1);

  }
  100% {
    transform: rotate(-45deg) translate(-7.5px, -7.5px) scale(1, 1);
    opacity: 1;
  }
}

@keyframes closing-out {
  100% {
    opacity: 0;
    transform: translate(0, 0) scale(0.1, 0.1);

  }
  99% {
    opacity: 1;
  }
  0% {
    transform: rotate(-45deg) translate(-7.5px, -7.5px) scale(1, 1);
    opacity: 1;
  }
}

@keyframes claime-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes move-kraft {
  0% {
  }
  100% {
    transform: translate(60px, -30px);
  }
}

@keyframes shrink-nav {
  0% {
    height: 99px;
  }
  100% {
    height: 58px;
  }
}

svg.logo-standard {
  position: absolute;
  top: 12px;
}

.move-kraft {
  animation: move-kraft forwards 1s;
}

.fadeOut {
  animation: claime-out forwards 0.8s;
}

a.navbar-brand {
  padding: 1rem 0 1rem 0.2rem;
  z-index: 100;

  @include media-breakpoint-down(md) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

polygon#close {
  opacity: 0;
  transform-origin: center;
  transform: rotate(0) scale(0.1);
  &.vis {
    animation: closing-in 0.4s cubic-bezier(.37, .01, .64, 0.4) forwards;
  }
  &.closing {
    animation: closing-out 0.4s cubic-bezier(.37, .01, .64, 0.4) forwards;
  }

}

.navbar-toggler {
  path {
    transition: all .3s ease-in-out;
  }
}
@include media-breakpoint-up(lg){
  li.nav-item{
    margin-top: 9px;
  }
}


div {
  ul.navbar-nav {

    li.nav-item {
      a.nav-link {
        transition: 0.2s color;
        color: $black;
        border-bottom: 0.25rem solid $white;
        &.active {
          border-bottom: 0.25rem solid $primary;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    ul.navbar-nav {
      height: 100vh;
      li.nav-item {
        a.nav-link {
          padding: 1rem;
          border-bottom: solid $primary 0.125rem;
          &.active {
            border-bottom-width: 0.125rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  nav.navbar {
    .container {
      svg#el_R-YtCOQNf {
        overflow: visible;
      }
      align-items: start;
      max-width: 100%;
    }
  }
  div.navbar-collapse {
    ul {
      //margin: 0 -2rem;
      width: 100%;
      transition: 0.1s ease-in background-color;
      background: $white;
    }
  }
}

.shrink-nav {
  @include media-breakpoint-up(md) {
    animation: shrink-nav 1s ease-in-out forwards;
  }
}

nav.navbar {
  @include media-breakpoint-up(lg) {
    height: 99px;
  }
}

button.navbar-toggler {
  z-index: 101;
  position: absolute;
  right: 27px;
  top: 9px;
  @include media-breakpoint-between(sm, md) {
    top: 12px;

  }
  margin-bottom: .5rem;
  // Medium devices (tablets, 768px and up)
  padding-right: 0;
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  ul.navbar-nav {
    height: auto;
    li:last-child a.nav-link {
      padding-right: 0;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
  .navbar {
    align-items: center;
  }

}