@font-face {
  font-family: 'AktifoA-Light';
  font-weight: 200;
  src: url('../FONTS/aktifo-a-light/aktifo-a-light.eot'); /* IE9 Compat Modes */
  src: url('../FONTS/aktifo-a-light/aktifo-a-light.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../FONTS/aktifo-a-light/aktifo-a-light.woff2') format('woff2'), /* Super Modern Browsers */
  url('../FONTS/aktifo-a-light/aktifo-a-light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../FONTS/aktifo-a-light/aktifo-a-light.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'AktifoA-Book';
  font-weight: 400;
  src: url('../FONTS/aktifo-a-book/aktifo-a-book.eot'); /* IE9 Compat Modes */
  src: url('../FONTS/aktifo-a-book/aktifo-a-book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../FONTS/aktifo-a-book/aktifo-a-book.woff2') format('woff2'), /* Super Modern Browsers */
  url('../FONTS/aktifo-a-book/aktifo-a-book.woff') format('woff'), /* Pretty Modern Browsers */
  url('../FONTS/aktifo-a-book/aktifo-a-book.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'AktifoA-Semi';
  src: url('../FONTS/aktifo-a-semibold/aktifo-a-semibold.eot'); /* IE9 Compat Modes */
  src: url('../FONTS/aktifo-a-semibold/aktifo-a-semibold.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../FONTS/aktifo-a-semibold/aktifo-a-semibold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../FONTS/aktifo-a-semibold/aktifo-a-semibold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../FONTS/aktifo-a-semibold/aktifo-a-semibold.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-weight: 400;
  font-family: 'AktifoA-BookOblique';
  font-style: italic;
  src: url('../FONTS/aktifo-a-book-oblique/aktifo-a-book-oblique.eot'); /* IE9 Compat Modes */
  src: url('../FONTS/aktifo-a-book-oblique/aktifo-a-book-oblique.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../FONTS/aktifo-a-book-oblique/aktifo-a-book-oblique.woff2') format('woff2'), /* Super Modern Browsers */
  url('../FONTS/aktifo-a-book-oblique/aktifo-a-book-oblique.woff') format('woff'), /* Pretty Modern Browsers */
  url('../FONTS/aktifo-a-book-oblique/aktifo-a-book-oblique.ttf') format('truetype') /* Safari, Android, iOS */
}

html {
  font-size: 14px;
  letter-spacing: -0.025em;
}

p:last-child, li:last-child{margin-bottom: 0;}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: AktifoA-Semi, sans-serif;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.11;
}

.subline {
  font-family: AktifoA-Light, sans-serif;
  font-weight: 200;
  line-height: 1.11;
  &.head {
    font-size: $h1-font-size;
    margin-top: 0;
  }
}

.intro {
  h2.h1 {
    font-size: 2.44 * $font-size-base;
  }
}


h3, .h3 {
  line-height: 1.18;
}

b, strong {
  font-family: AktifoA-Semi, sans-serif;
  font-weight: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-down(sm) {
  b, strong, p, a, ul li, .btn {
    font-size: 18px;
  }
}

@include media-breakpoint-up(lg) {
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  html {
    font-size: 16px;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  html {
    font-size: 18px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}