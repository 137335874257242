/*#######################################################
###################### SECTION HAND #######################
#########################################################*/
@include media-breakpoint-up(xl) {
  section.hand {
    @include media-breakpoint-up(xl){
      .row {
        height: 66vh;
      }
    }
  }
}

section.hand {
  margin-top: 0;
}

div.hand {
  svg {
    -webkit-animation: animationFrames ease-in-out 3s;
    -webkit-transform-origin: center;
    -webkit-animation-iteration-count: infinite;

    @-webkit-keyframes animationFrames {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(10deg);
      }
    }
    max-width: 100%;
    @include media-breakpoint-down(xs) {
      height: 250px;
    }
  }
}

/*#######################################################
###################### SECTION TEAM #######################
#########################################################*/
@include media-breakpoint-between(lg, xl){

  section.team-mariella.mb-23{
    margin-bottom: 3rem;
  }
}
@include media-breakpoint-down(lg) {
  section.team-mariella {
    .container-fluid {
      margin-bottom: 3rem;
    }
  }
}

/*#######################################################
###################### SECTION BEHANDLUNGSSPEKTRUM ########
#########################################################*/
section.behandlungskonzept {
  .row{
    overflow: visible;
  }
  .orange-border {
    margin-left: 2rem;
    padding-left: 2rem;
    border-left: solid 0.25em $primary;
    h3 {
      position: relative;
      &:before {
        transform: translateX(-3.14rem);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        background: white;
        border: solid 0.25rem #ED8B00;
      }
    }
    & + div {
      margin-left: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    .orange-border {
      margin-left: 1.5rem;
      padding-left: 5rem;
      border-left: solid 0.25em $primary;
      h3 {
        position: relative;
        &:before {
          transform: translateX(-6.59rem);
          height: 3rem;
          width: 3rem;
        }
      }
      & + div {
        margin-left: auto;
      }
    }
  }
  article + article {
    margin: 4rem 0 0 0;
    p {
      position: relative;
      &:before {
        transform: translateX(-2.9rem);
        content: "";
        position: absolute;
        top: 0.2rem;
        left: 0;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        background: white;
        border: solid 0.25rem $primary;
      }
    }
    @include media-breakpoint-up(lg) {
      p {
        &:before {
          transform: translateX(-5.84rem);
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }

  .card {
    border: none;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 26px 0px rgba(0, 0, 0, 0.16);
  }
  .deco {
    margin-top: -2rem;
  }
}

/*#######################################################
###################### SECTION KONTAKT #######################
#########################################################*/
section.contact {
  .row {
    overflow: visible;
  }
  position: relative;
  color: $white;
  a {
    color: $white;
    text-decoration: none;
  }
  img.icon {
    padding: 0 0.3em 0.3em 0;
  }
  span.plz {
    padding-left: 1.5em;
  }
  .left {
    &:after {
      display: none;
      overflow: hidden;
      position: absolute;
      left: 100%;
      transform: translateY(-91%);
      height: 580px;
      width: calc(50vw - (#{$grid-gutter-width} / 2));
      background-image: url("../images/duo-kraft-pattern-web.svg");
      background-size: 250px 250px;
      content: "";

      @include media-breakpoint-up(xl) {
        max-width: 41vw;
      }
      @include media-breakpoint-only(lg) {
        max-width: 40vw;
        height: 580px;
      }
      @include media-breakpoint-between(md, lg) {
        height: 680px;
      }
      @include media-breakpoint-between(sm, md) {
        height: 600px;
      }
      @include media-breakpoint-down(sm) {
        visibility: hidden;
        width: 0;
      }
    }
  }

}

/*#######################################################
###################### SECTION FOOTER #######################
#########################################################*/
footer a {
  color: $black;
  transition: 0.1s all ease-in;
  &:hover{
    //transform: scale(1.2, 1.2);
  }
}


/*#######################################################
###################### SECTION IMPRESSUM #######################
#########################################################*/
section.impressum{
  .icon {
    padding: 0 0.3em 0.3em 0;
  }
  .col img{
    @include media-breakpoint-down(md){
      width: 80%;
      margin: 0 auto;
    }
  }
}
body.impressum{
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}