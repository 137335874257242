$grid-gutter-width: 40px;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1210px
);
$primary:       #ED8B00;
$secondary:     #ffdd67;
$black: #000;
$light: #CBCCCC;
$paragraph-margin-bottom: 1.5rem;
$navbar-light-color: #000;
$font-family-base: "AktifoA-Book", sans-serif;
$body-color: $black;
$navbar-nav-link-padding-x: 1rem;
$navbar-padding-y: 0;
$btn-border-radius: 26px;
$link-color: inherit;
$link-hover-color: inherit;
$link-hover-decoration: none;


$line-height-base: 1.5;

$font-size-base: 1rem;
$font-size-sm:                $font-size-base * .875;
$h1-font-size:                $font-size-base * 3;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.5;

$spacer: 1rem;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 5),
        2r: ($spacer * 2),
);